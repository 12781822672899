function kilometrageController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.regions = {};
    vm.filter = {};
    vm.filter.user_code = '';
    vm.filter.depot_code = '';
    vm.filter.date_debut = '';
    vm.filter.date_fin = '';;
    vm.getKilometrages = function() {
        WS.getData('kilometrages/withimage', vm.filter)
            .then(function(response) {
                if (response.status == 200) {
                    vm.kilometrages = response.data;
                    $scope.$apply();
                    // $('.table').DataTable({
                    //     "language": DatatableLang
                    // });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };



    vm.exportKilometrages = function() {
        var filename = "kilometrages By commercial-" + vm.filter.user_code + '-' 
                                                     + vm.filter.depot_code + '-' 
                                                     + vm.filter.date_debut + '-' 
                                                     + vm.filter.date_fin;

            var file = {
                sheetid: filename,
                headers: true,
                caption: {
                    title: filename,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'code', title: 'Code' },
                    { columnid: 'user', title: 'Commercial' },
                    { columnid: 'depot', title: 'Dépot' },
                    { columnid: 'date', title: 'Date' },
                    { columnid: 'heure_debut', title: 'Heure début' },
                    { columnid: 'heure_fin', title: 'Heure fin' },
                    { columnid: 'kilometrage_debut', title: 'kilometrage début' },
                    { columnid: 'kilometrage_fin', title: 'kilometrage fin' },
                    { columnid: 'distance', title: 'Distance' },
                ],
                row: {
                    style: function(sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.kilometrages]);
    };


    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.users = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectDepots = function() {
        WS.get('depots/selectDepot').then(
            function(response) {
                vm.depots = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };

    vm.getImage= function(code, type_image) {
        WS.getData('kilometrages/getImage', {code, type_image}).then(
            function(response) {
                vm.current_image = response.data.path;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };

}
export default {
    name: 'kilometrageController',
    fn: kilometrageController
};