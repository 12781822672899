
 function chequierController(DatatableLang, $state, $scope, WS, $window, $translate) {
     'ngInject';
     const vm = this;
     vm.current_date = moment().format('DD/MM/YYYY');

     vm.chequier = {};
     vm.nbr_chequier = "50"
     vm.addCheque = () => {
        WS.post("chequier/addCheque", vm.chequier)
        .then(function (response) {
          if (response.data.success) {
            swal(
              $translate.instant("Succès"),
              $translate.instant("cheque ajouté avec Succès"),
              "success"
            )
              .then(function () {
                $window.location.reload();
              })
              .catch(function (error) {
                $window.location.reload();
              });
          }
          //vm.btn_save = true;
          $scope.$apply();
        })
        .then(null, function (error) {
          console.log(error);
        });
     }

     vm.end_cheque_25 = 25;
     vm.end_cheque_50 = 50;
     vm.end_cheque_100 = 100;
     vm.last_numero_cheque = 0;
     vm.getLastChequeNum = () => {
      vm.chequier.start=0;
      vm.chequier.end=Number(vm.nbr_chequier);


      _.each(vm.select_banques, element => {
        var id=element.id.toString();
        console.log(vm.chequier.banque_id , id)
        if(vm.chequier.banque_id == id){
          vm.chequier.compte = element.numero_compte.toString();
        }
      })

      
        WS.getData("chequier/getLastChequeNum", {banque_id : vm.chequier.banque_id})
          .then(function (response) {
            if (response.status == 200) {
              if(response.data.numero_cheque){
                vm.last_numero_cheque = Number(response.data.numero_cheque);
                vm.chequier.start = Number(vm.last_numero_cheque) + 1;
                vm.chequier.end = Number(vm.last_numero_cheque) + Number(vm.nbr_chequier);
              }else{
                vm.last_numero_cheque = 1;
                vm.chequier.start = Number(vm.last_numero_cheque);
                vm.chequier.end = Number(vm.last_numero_cheque) + Number(vm.nbr_chequier) - 1;
              }
              $scope.$apply();
            } else {
            }
          })
          .then(null, function (error) {
            console.log(error);
          });
     }

     vm.recalculateStartEnd = () => {
        vm.chequier.start = Number(vm.last_numero_cheque);
        vm.chequier.end = Number(vm.last_numero_cheque) + Number(vm.nbr_chequier) - 1;

        
     };


     vm.selectBanques = function () {
      WS.getData("compte_banques/selectBanques")
        .then(function (response) {
          if (response.status == 200) {
            vm.select_banques = response.data;
            _.each(vm.select_banques, element => {
              if(element.principale== 1){
                vm.chequier.banque_id = element.id.toString();
                vm.chequier.compte = element.numero_compte.toString();
                vm.getLastChequeNum();
              }
            })
  
            $scope.$apply();
          } else {
          }
        })
        .then(null, function (error) {
          console.log(error);
        });
    };

     vm.getChequier = () => {
        WS.getData("chequier")
        .then(function (response) {
          if (response.status == 200) {
            vm.cheques = response.data.cheques;
            vm.cheques_en_circulation = response.data.cheques_en_circulation;
            $scope.$apply();
          } else {
          }
        })
        .then(null, function (error) {
          console.log(error);
        });
     }


     vm.updateCheque = (cheque) => {
        WS.post("chequier/updateCheque", cheque)
        .then(function (response) {
          if (response.data.success) {
            swal(
              $translate.instant("Succès"),
              $translate.instant("cheque modifié avec Succès"),
              "success"
            )
              .then(function () {
               // $window.location.reload();
              })
              .catch(function (error) {
                //$window.location.reload();
              });
          }
          //vm.btn_save = true;
          $scope.$apply();
        })
        .then(null, function (error) {
          console.log(error);
        }); 
     };
    
 }
 export default {
     name: 'chequierController',
     fn: chequierController
 };