function carburantController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.regions = {};

    vm.getCarburants = function() {
        WS.get('carburants/withimage')
            .then(function(response) {
                if (response.status == 200) {
                    vm.carburants = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getCarburantByDate = function(date_debut = null, date_fin = null, commercial = null) {
        var url = 'carburants/bydate/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url)
            .then(function(response) {
                if (response.status == 200) {
                    vm.carburants = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.exportCarburantByDate = function(date_debut = null, date_fin = null, commercial = null, gouvernorat = null) {
        var url = 'carburants/bydate/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url).then(function(response) {
            var filename = "Carburant By commercial-" + date_debut + '-' + date_fin;

            var file = {
                sheetid: "Carburant By commercial-" + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: "Carburant By commercial-" + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'codeCommercial', title: 'Code' },
                    { columnid: 'commercial', title: 'Commercial' },
                    { columnid: 'ticket_code', title: 'Ticket' },
                    { columnid: 'montant', title: 'Montant' },
                    { columnid: 'date', title: 'Date' },
                ],
                row: {
                    style: function(sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function(error) {
            console.log(error);
        });
    };

    vm.getCoutVisite = (date_debut = null, date_fin = null, code) => {
        var url = 'carburants/cout/' + date_debut + '/' + date_fin + '/' + code;
        //console.log(url);
        WS.get('carburants/cout/' + date_debut + '/' + date_fin + '/' + code)
            .then(function(response) {
                vm.coutvisites = response.data;

                jQuery.noConflict();
                $('#coutvisiteModal').modal('show');
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.getTotalMontant = function(list) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i]['montant']);
        }
        return total;
    }

    vm.selectUsers = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

}
export default {
    name: 'carburantController',
    fn: carburantController
};