function mouvementsController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;

    vm.mouvements = {};



    vm.getCommercialByEquipement = function (code, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['code'] == code) {
                return liste[i];
            }
        }
    }
    vm.getMouvements = function() {
        WS.getData('mouvements', vm.mvtdate)
            .then(function(response) {

                if (response.status == 200) {
                    vm.mouvements = response.data;
                    $scope.$apply();
                    // $('.table').DataTable({
                    //     "language": DatatableLang,
                    //     paging: false,
                    //     ordering: false
                    // });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.updatemouvement = function(id) {
        WS.get('mouvements/updateLigneMouvemnts/' + id)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Ligne Mouvemnts modifié avec Succès',
                        'success'
                    ).then(function() {

                        //    $window.location.reload();

                    })
                }
            })
            .then(null, function(error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }

    vm.selectUsers = function() {
        WS.get('users/selectUsers')
            .then(function(response) {
                vm.users = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

   

    vm.correctionMouvement = function(correction) {
        if (vm.files) {
            correction.file = vm.files[0];
        } else {
            correction.file = '';
        }

        WS.postwithimg('mouvements/correction', correction, "POST")
            .then(function(response) {
                swal(
                    'Succé',
                    'Correction ajoutée avec succées',
                    'success'
                ).then(function() {
                    $window.location.reload();
                    $scope.$apply();
                });
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.refreshMouvements = function() {
        WS.get('mouvements')
            .then(function(response) {
                vm.mouvements = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getByDate = function(mvtdate) {
         if (mvtdate.date_debut == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date debut', 'warning');
            return;
        } else if (mvtdate.date_fin == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date fin', 'warning');
            return;
        } else {
            if (mvtdate.user_code != undefined && mvtdate.user_code != '') {
                var url = 'mouvements/getByDate/' + mvtdate.date_debut + '/' + mvtdate.date_fin + '/' + mvtdate.user_code;
            } else {
                var url = 'mouvements/getByDate/' + mvtdate.date_debut + '/' + mvtdate.date_fin;
            }
            WS.getData(url, mvtdate)
                .then(function(response) {
                    //vm.mouvements={};
                    vm.mouvements = response.data;
                    $scope.$apply();
                })
                .then(null, function(error) {
                    console.log(error);
                });
        }
    };

    vm.getInventaire = function() {
        var url = 'mouvements/inventaire/' + vm.commercial;

        WS.get(url)
            .then(function(response) {
                console.log(response);
                if (response.status == 200) {
                    vm.inventaires = response.data;

                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getLibelleBycode = function(code, liste, attribute) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }

        }
    };


    vm.selectFournisseurs = function () {
        WS.get("fournisseurs/selectFournisseursWithCode")
          .then(function (response) {
            if (response.status == 200) {
              vm.select_frs = response.data;
              $scope.$apply();
            } else {
            }
          })
          .then(null, function (error) {
            console.log(error);
          });
      };


      vm.selectProduits = function () {
        WS.get("produits/selectProduitsWithCode")
          .then(function (response) {
            if (response.status == 200) {
              vm.selectproduits = response.data;
              $scope.$apply();
            } else {
            }
          })
          .then(null, function (error) {
            console.log(error);
          });
      };

    //   vm.selectProduits = function() {
    //     WS.get('produits/selectProduit')
    //         .then(function(response) {
    //             vm.produits = response.data;
    //             $scope.$apply();
    //         })
    //         .then(null, function(error) {
    //             console.log(error);
    //         });
    // };

      

    vm.getcommercial = function() {
        WS.get('users/query/grade="LIV"', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.commercials = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.exportExcel = function(mvtdate) {
         if (mvtdate.date_debut == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date debut', 'warning');
            return;
        } else if (mvtdate.date_fin == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date fin', 'warning');
            return;
        } else {
            if (mvtdate.user_code != undefined && mvtdate.user_code != '') {
                var url = 'mouvements/getByDate/' + mvtdate.date_debut + '/' + mvtdate.date_fin + '/' + mvtdate.user_code;
            } else {
                var url = 'mouvements/getByDate/' + mvtdate.date_debut + '/' + mvtdate.date_fin;
            }
            WS.get(url)
                .then(function(response) {

                    var filename = 'Mouvements-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function(error) {
                    console.log(error);
                });
        }
    };


    vm.exportExcelTradisco = function(mvtdate) {
        console.log(mvtdate);
        if (mvtdate.date_debut == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date debut', 'warning');
            return;
        } else if (mvtdate.date_fin == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date fin', 'warning');
            return;
        } else {
            if (mvtdate.user_code != undefined && mvtdate.user_code != '') {
                var url = 'mouvements/exportTradisco/' + mvtdate.date_debut + '/' + mvtdate.date_fin + '/' + mvtdate.user_code;
            } else {
                var url = 'mouvements/exportTradisco/' + mvtdate.date_debut + '/' + mvtdate.date_fin;
            }
            WS.get(url)
                .then(function(response) {

                    var filename = 'Mouvements-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsvTradisco, response.data]);
                })
                .then(null, function(error) {
                    console.log(error);
                });
        }
    };

    var stylecsv = {
        sheetid: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
        caption: {
            title: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
        },
        //style:'background:#fff',
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'created_at', title: 'Date' },
            { columnid: 'numero', title: 'Numero Operation' },
            { columnid: 'code_commercial', title: 'Code Commercial' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'type', title: 'Type' },
            { columnid: 'code', title: 'Code' },
            { columnid: 'code_erp_produit', title: 'code_erp produit' },
            { columnid: 'fournisseur', title: 'Fournisseur' },
            { columnid: 'soussociete', title: 'Sous Societe' },
            { columnid: 'produit', title: 'Produit' },
            { columnid: 'quantite', title: 'Quantite' },
            { columnid: 'etat', title: 'Etat' }
        ],
        row: {
            style: function(sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };


    var stylecsvTradisco = {
        sheetid: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
        caption: {
            title: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
        },
        //style:'background:#fff',
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'date', title: 'Date' },
            { columnid: 'numero', title: 'Numero Operation' },
            { columnid: 'client_code', title: 'CODE CLIENT' },
            { columnid: 'client', title: 'NOM DU CLIENT' },
            { columnid: 'soussociete', title: 'Sous Societe' },
            { columnid: 'adresse_facturation', title: 'ADRESSE' },
            { columnid: 'region', title: 'REGION' },
            { columnid: 'matricule_fiscale', title: 'MATRICULE FISCALE' },
            { columnid: 'demande_facture', title: 'DEMANDE FACTURE' },
            { columnid: 'user_code', title: 'Code Commercial' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'type', title: 'Type' },
            { columnid: 'code', title: 'Code' },
            { columnid: 'code_erp_produit', title: 'code_erp produit' },
            { columnid: 'produit', title: 'Produit' },
            { columnid: 'prix_ht', title: 'PRIX HT' },
            { columnid: 'remise', title: 'TAUX DE REMISE' },
            { columnid: 'remiseEspece', title: 'REMISE ESPECE' },
            { columnid: 'mode_paiement', title: 'mode paiement' },
            { columnid: 'quantite', title: 'Quantite' },
            { columnid: 'etat', title: 'Etat' }
        ],
        row: {
            style: function(sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };

    $scope.moveCSV = function(element) {
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.csvfile.filetxt = event.target.result;
            $scope.$apply(function() {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };

    vm.importWavesoftMouvement = function() {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('bl/importWavesoftMouvement', vm.csvfile, "POST")
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            }, function(error) {
                console.log(error);
            });
    };

    vm.importExcelMouvement = function() {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('mouvements/importExcelMouvement', vm.csvfile, "POST")
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            }, function(error) {
                console.log(error);
            });
    };

}
export default {
    name: 'mouvementsController',
    fn: mouvementsController
};