function produitsController(DatatableLang, $translate, $state, $scope, WS, $filter, $window, $parse, AppSettings) {
    'ngInject';
    const vm = this;
    vm.produits = {};
    vm.compositionspack = {};
    vm.composition = {};
    vm.gratuites = {};
    vm.augmentation = {};
    vm.gammes = {};
    vm.sousfamilles = {};
    vm.familles = {};
    vm.activites = {};
    vm.marques = {};
    vm.zones = {};
    vm.regions = {};
    vm.fournisseurs = {};
    vm.detail = {};
    vm.files = [];
    vm.fiche = [];
    vm.getpaginate = function (page = null, perpage = 20) {
        WS.getData('produits/paginate/' + perpage,{
            page,perpage,
            gamme_code : vm.gamme_code,
            famille_code : vm.famille_code,
            sousfamille_code : vm.sousfamille_code,
            marque_code : vm.marque_code,
            fournisseur_code : vm.fournisseur_code
        })
            .then(function (response) {
                vm.type = 'get';
                vm.produits = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = Number(response.data.per_page);
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.search = function (perpage, search, page = 1) {
        if (search == '') { vm.getpaginate(); return; }
        if (search != '' || search != null || search != undefined) {
            WS.get('produits/search/' + perpage + '/' + search, null, page).then(
                function (response) {
                    vm.type = "search";
                    vm.query = response.data.search;
                    vm.produits = response.data.data.data;
                    vm.current_page = response.data.data.current_page;
                    vm.previous = vm.current_page - 1;
                    vm.next = vm.current_page + 1;
                    vm.from = response.data.data.form;
                    vm.last_page = response.data.data.last_page;
                    vm.next_page_url = response.data.data.next_page_url;
                    vm.per_page = Number(response.data.data.per_page);
                    vm.prev_page_url = response.data.data.prev_page_url;
                    vm.to = response.data.data.to;
                    vm.total = response.data.data.total;
                    vm.paginate = [];
                    for (var i = 1; i <= vm.last_page; i++) {
                        vm.paginate.push(i);
                    }
                    $scope.$apply();
                },
                function (error) {
                    console.log(error);
                })
        } else {
            vm.getpaginate();
        }
    };


    vm.selectProduit = function () {
        WS.get('produits/selectProduit').then(
            function (response) {
                vm.selectproduit = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    }
    /************************************************************ condition gratuites ***************************************/
    vm.getconditiongratuites = function () {
        WS.get('gratuites/getconditiongratuites/' + $state.params.code).then(
            function (response) {
                vm.conditions = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

   

    vm.getconditiongratuitesByCode = function (code) {
        WS.get('gratuites/getconditiongratuites/' + code).then(
            function (response) {
                vm.conditionsgratuitesbycode = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };
    vm.StoreConditionGratuite = function (gratuite) {

        gratuite.gratuite_code = $state.params.code;
        WS.post('gratuites/StoreConditionGratuite', gratuite).then(
            function (response) {
                swal(
                    'Succès',
                    'Condition ajouté avec Succès',
                    'success'
                ).then(function () {
                    $window.location.reload();
                });
            },
            function (error) {
                console.log(error);
            });
    };

    vm.getgroupes = function () {
        WS.get('groupes', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.groupes = response.data;
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.UpdateConditionGratuite = function (gratuite) {
        WS.put('gratuites/UpdateConditionGratuite/' + gratuite.id, gratuite).then(
            function (response) {
                swal(
                    'Succès',
                    'Condition modifié avec Succès',
                    'success'
                ).then(function () {
                    $window.location.reload();
                });
            },
            function (error) {
                console.log(error);
            });
    };
    vm.deleteConditionGratuite = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette Condition!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('gratuites/deleteConditionGratuite/' + id).then(
                function () {
                    swal(
                        'Supprimée!',
                        'Condition Supprimée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette Condition',
                        'warning'
                    );
                }
            );
        });
    };
    /*****************************************************fin condition gratuites ***************************************/

    vm.selectGratuites = function () {
        WS.get('gratuites/selectGratuites').then(
            function (response) {
                vm.selectgratuite = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectGratuitesActif = function () {
        WS.get('gratuites/selectGratuitesActif').then(
            function (response) {
                vm.selectgratuiteactif = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };
    vm.get = function () {
        WS.get('produits')
            .then(function (response) {

                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                    $('.table_produits').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    
    //show custum pages in pagination : pagination inside pagination
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 5 || page < current - 5) {
            return false;
        } else {
            return true;
        }
    }
    //vider la recherche
    vm.initsearch = function (searchbox) {
        if (searchbox == '') {
            vm.getpaginate();
        }
    }
    vm.getDetails = function (id) {
        WS.get('produits/' + $state.params.id)
            .then(function (response) {
                vm.detail.image = '';
                vm.detail = response.data;
                vm.detail.is_pack = vm.detail.is_pack.toString();
                vm.detail.actif = vm.detail.actif.toString();
                vm.detail.soumis_retenue = vm.detail.soumis_retenue.toString();
                vm.detail.soumis_r = vm.detail.soumis_r.toString();
                vm.detail.chargement = vm.detail.chargement.toString();
                vm.detail.hors_vente = vm.detail.hors_vente.toString();
                vm.detail.soumis_gratuite = vm.detail.soumis_gratuite.toString();
                vm.detail.soumis_cadeaux = vm.detail.soumis_cadeaux.toString();
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getGammes = function () {
        WS.get('gammes/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.gammes = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getFournisseurs = function () {
        WS.get('fournisseurs', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.fournisseurs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getMarques = function () {
        WS.get('marques/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.marques = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getSousFamilles = function () {
        WS.get('sousfamilles/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.sousfamilles = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getFamilles = function () {
        WS.get('familles/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.familles = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getActivites = function () {
        WS.get('activites', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.activites = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getZones = function () {
        WS.get('zones', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.zones = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getRegions = function () {
        WS.get('regions', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.regions = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.init = function () {
        vm.detail = {
            activite_code: "ACTIVITE3",
            code: "COD11",
            code_a_barre: "1234567898765",
            colisage: 1,
            poids: 90,
            volume: 32,
            type: "PC",
            famille_code: "FAMILLE1",
            gamme_code: "GAMME2",
            hauteur: 2,
            largeur: 30,
            libelle: "Mac book pro",
            marque_code: "MARQUE2",
            prix_ht: 1000,
            profondeur: 17,
            unite: "33",
            qte_min: "4",
            sousfamille_code: "SOUSFAMILLE2",
            tva: 18
        }
    }
    vm.getSousSocietes = function () {
        WS.get('soussocietes/selectSoussocietes')
            .then(function (response) {
                if (response.status == 200) {
                    vm.soussocietes = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.store = function () {
        vm.detail.image = vm.files[0];
        vm.detail.fiche = vm.fiche[0];
        var route;
        var method;
        var msg;
        if ($state.params.id) {
            route = 'produits/' + $state.params.id;
            method = 'PUT';
            msg = 'modifiée';
        } else {
            route = "produits/postStoreForDashboard";
            method = "POST";
            msg = 'ajoutée';
        }
        //console.log(vm.detail); return;
        WS.postwithimg(route, vm.detail, method)
            .then(function (response) {
                if (response.data.status == true) {

                    swal(
                        'Succès',
                        'Produit ' + msg + ' avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Attention',
                        response.data.message,
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };


    vm.copie = function () {
        vm.detail.image = vm.files[0];
        vm.detail.fiche = vm.fiche[0];
        var route;
        var method;
        var msg;
  
        route = "produits/postStoreForDashboard";
        method = "POST";
        msg = 'ajoutée';
        WS.postwithimg(route, vm.detail, method)
            .then(function (response) {
                if (response.data.status == true) {
                    swal(
                        'Succès',
                        'Produit ' + msg + ' avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Attention',
                        response.data.message,
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };

    $scope.uploadedFile = function (element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.detail.image = event.target.result
            $scope.$apply(function () {
                vm.files = element.files;

            });
        }
        reader.readAsDataURL(element.files[0]);
        $scope.$apply();
    };

    $scope.uploadedFiche = function (element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.detail.fiche = event.target.result
            $scope.$apply(function () {
                vm.fiche = element.files;

            });
        }
        reader.readAsDataURL(element.files[0]);
        $scope.$apply();
    };

    vm.getCompositionsPack = function () {
        vm.pack_code = $state.params.code;
        WS.get('compositionspack/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.compositionspack = response.data;

                    $scope.$apply();
                    $('.table_pack').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getPack = function (code) {

        WS.get('produits/query/is_pack=1')
            .then(function (response) {
                if (response.status == 200) {
                    vm.packs = response.data;
                    $scope.$apply();
                    $('.table_pack').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.updatePack = function (detail) {

        WS.put('compositionspack/' + detail.id, { 'qte': detail.qte, 'prix_ht': detail.prix_ht, 'numero_config': detail.numero_config })
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Compositions pack mis à jour avec Succès',
                        'success'
                    )
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }
    vm.deletePack = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette Composition pack!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('produits/' + id).then(
                function () {
                    swal(
                        'Supprimée!',
                        'Pack Supprimée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette ce pack',
                        'warning'
                    );
                }
            );
        }, function (error) {

        });
    };

    vm.deleteProduit = function (id) {
        swal({
            title: 'Supprimer',
            text: "voulez-vous vraiment supprimer cet élément !",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            console.log(id);
            WS.delete('produits/' + id).then(
                function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Supprimée!',
                            'Produit Supprimée avec Succès',
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    } else {
                        swal(
                            'Warning',
                            response.data.message,
                            'warning'
                        )
                    }
                }
            );
        }, function (error) {

        });
    };

    vm.deleteCompositionPack = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette Composition pack!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('compositionspack/' + id).then(
                function () {
                    swal(
                        'Supprimée!',
                        'Composition pack Supprimée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette Composition pack',
                        'warning'
                    );
                }
            );
        }, function (error) {

        });
    };

    vm.ajouterRemise = function (composition) {
        console.log(composition);
        WS.post('promotions', composition)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Promotion ajouté au avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }

    vm.ajouterCompositionPack = function (composition) {
        WS.post('compositionspack', composition)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Compostion Ajouté  avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }
    vm.selectedProduit = function (composition) {
        var produit = $filter('filter')(vm.produits, { code: composition.produit_code })[0];
        composition.prix_ht = produit.prix_ht;
    }
    vm.getGratuites = function () {
        WS.get('gratuites/all')
            .then(function (response) {

                if (response.status == 200) {
                    vm.gratuites = response.data;
                    $scope.$apply();
                    $('.table_gratuites').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getAugmentation = function () {
        WS.get('augmentations')
            .then(function (response) {

                if (response.status == 200) {
                    vm.augmentations = response.data;
                    $scope.$apply();
                    $('.table_gratuites').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    /********************************fidelitesss*****************************************/



    vm.getRemises = function () {
        WS.get('promotions/all')
            .then(function (response) {
                if (response.status == 200) {
                    vm.remises = response.data;
                    $scope.$apply();
                    $('#exemple').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getRemisesActif = function () {
        WS.get('promotions/actif')
            .then(function (response) {
                vm.remisesactif = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.updatePromotion = function (detail) {
        console.log(detail);
        WS.put('promotions/' + detail.id, detail)

            .then(function (response) {
                swal(
                    'Succès',
                    'Promotion mis à jour avec Succès',
                    'success'
                ).then(function () {
                    $window.location.reload();
                });
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }

    vm.updateGratuite = function (detail) {
        var data = {
            gratuit_code: detail.gratuit_code,
            libelle: detail.libelle,
            quantite_gratuit: detail.quantite_gratuit,
            isactif: detail.isactif,
            date_debut: detail.date_debut,
            date_fin: detail.date_fin
        };
        WS.put('gratuites/' + detail.id, data)

            .then(function (response) {
                swal(
                    'Succès',
                    'Gratuité mis à jour avec Succès',
                    'success'
                ).then(function () {
                    $window.location.reload();
                });
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }
    vm.deleteGratuite = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette gratuite!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('gratuites/' + id).then(
                function () {
                    swal(
                        'Succès',
                        'gratuite supprimée avec Succès.',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas suprimer cette gratuite!!',
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error)
        });

    }


    vm.deletePromotion = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette promotion",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function () {
            WS.delete('promotions/' + id).then(
                function () {
                    swal(
                        'Succès!',
                        'Promotion supprimée avec Succès.',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas effacer cette Promotion!!',
                        'warning'
                    );
                }
            );
        });
    }

    vm.ajouterGratuite = function (composition) {
      
        WS.post('gratuites', composition)
            .then(function (response) {
                if (response.data.success) {
                    swal(
                        'Succès',
                        'gratuite a ete ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.ajouterAugmentation = function (data) {
      
        WS.post('augmentations', data)
            .then(function (response) {
                if (response.data.success) {
                    swal(
                        'Succès',
                        'Augmentation a ete ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }



    /*  $(document).ready(function() {
        $('input[type="file"]').on('change', function() {
            if (($(this))[0].files.length > 0) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    vm.detail.base64_image = e.target.result;
                    var img = $('#img');
                    img.css({
                        'background-image'  : 'url(' + e.target.result + ')',
                        'width'             : 400 ,
                        'height'            : 300, 
                        'margin-top'        : 15,
                        'background-repeat' : 'no-repeat', 
                        'background-size'   : 'cover !important'
                    });
                }
                reader.readAsDataURL(($(this))[0].files[0]);
            }
        });


    });

*/

    vm.getProduitByCode = function (code, liste) {


        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['code'] == code) {
                return liste[i];
            }

        }
    }


    /************************* affectation clients **********************************/
    vm.getPromotions = function () {
        WS.get('promotions').then(
            function (response) {
                vm.promotions = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.getCategoriesCommerciale = function () {
        WS.get('categoriescommerciale').then(
            function (response) {
                vm.categoriescommerciale = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.addAffectationClient = function (affectation) {
        WS.post('promotions/addAffectationClient', affectation).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Affectation Client ajouté avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    )
                }
            },
            function () {
                console.log(error);
            }
        );
    };

    vm.addAffectationClientGratuites = function (affectation) {
        WS.post('gratuites/addaffectationclient', affectation).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Affectation Client ajouté avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    )
                }
            },
            function () {
                console.log(error);
            }
        );
    };

    vm.findAffectationClient = function () {
        WS.get('promotions/findAffectationClient').then(
            function (response) {
                if (response.status == 200) {
                    vm.affectationclient = response.data;

                    $scope.$apply();
                    $('.table_aff').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.findAffectationClientGratuite = function () {
        WS.get('gratuites/findaffectationclient').then(
            function (response) {
                if (response.status == 200) {
                    vm.affectationclientgratuites = response.data;

                    $scope.$apply();
                    $('.table_aff_gratuite').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.updateAffectationClient = function (id, update) {
        WS.put('promotions/updateAffectationClient/' + id, update).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Affectation Client modifié avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'success'
                    );
                }
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.deleteAffectationClient = function (id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer cette Affectation Client!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('promotions/deleteAffectationClient/' + id).then(
                function () {
                    swal(
                        $translate.instant('Supprimée!'),
                        $translate.instant('Affectation Client Supprimée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('Vous ne pouvez pas supprimer cette Affectation Client'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    };
    /************************* Fin affectation clients **********************************/

    /*************************** affectation produit ************************************/
    vm.addAffectationProduit = function (affectation) {
        WS.post('promotions/addAffectationProduit', affectation).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succè'),
                        $translate.instant('Affectation Produit ajouté avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    )
                }
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.findAffectationProduit = function () {
        WS.get('promotions/findAffectationProduit').then(
            function (response) {
                if (response.status == 200) {
                    vm.affectationproduit = response.data;
                    $scope.$apply();
                    $('.table_affpr').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            },
            function (error) {
                console.log(error);
            }
        );
    };


    vm.findAffectationProduitByPromotion = function (code) {
        WS.get('promotions/findaffectationproduitbycode/' + code).then(
            function (response) {
                vm.findaffectationproduitbycode = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.findAffectationClientByPromotion = function (code) {
        WS.get('promotions/findaffectationclientbycode/' + code).then(
            function (response) {
                vm.aff_clients_details = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };



    vm.updateAffectationProduit = function (id, update) {
        WS.put('promotions/updateAffectationProduit/' + id, update).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succè'),
                        $translate.instant('Affectation Produit modifié avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Warning'),
                        $translate.instant(response.data.message),
                        'success'
                    );
                }
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.deleteAffectationProduit = function (id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer cette Affectation Produit"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('promotions/deleteAffectationProduit/' + id).then(
                function () {
                    swal(
                        $translate.instant('Supprimée'),
                        $translate.instant('Affectation Produit Supprimée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('Vous ne pouvez pas supprimer cette Affectation Produit'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    };


    vm.exportExcel = function (bldate, withavoir = null) {

        var url = 'produits/exportExcel';
        WS.get(url)
            .then(function (response) {
                var filename = 'Produits-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                var stylecsv = {
                    sheetid: filename,
                    headers: true,
                    caption: {
                        title: filename,
                        style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                    },
                    //style:'background:#fff',
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [
                        { columnid: 'code', title: 'Code' },
                        { columnid: 'produit', title: 'Produit' },
                        { columnid: 'marque', title: 'Marque' },
                        { columnid: 'gamme', title: 'Gamme' },
                        { columnid: 'famille', title: 'Famille' },
                        { columnid: 'sousfamille', title: 'Sous_Famille' },
                        { columnid: 'prix_ttc', title: 'Prix_ttc' },
                        { columnid: 'colisage', title: 'Colisage' },
                        { columnid: 'ordre', title: 'Ordre' }
                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);

            })
            .then(null, function (error) {
                console.log(error);
            });

    };
    /************************* Fin affectation produit **********************************/
    //show and hide for remise form 
    $scope.showGamme = true;
    $scope.showFamille = true;
    $scope.showSousfamille = true;
    $scope.showProduit = true;

    $scope.marqueOnly = function () {
        $scope.showMarque = false;
        $scope.showGamme = true;
        $scope.showFamille = true;
        $scope.showSousfamille = true;
        $scope.showProduit = true;
    }
    $scope.gammeOnly = function () {
        $scope.showMarque = true;
        $scope.showGamme = false;
        $scope.showFamille = true;
        $scope.showSousfamille = true;
        $scope.showProduit = true;
    }
    $scope.familleOnly = function () {
        $scope.showMarque = true;
        $scope.showGamme = true;
        $scope.showFamille = false;
        $scope.showSousfamille = true;
        $scope.showProduit = true;
    }
    $scope.sousfamilleOnly = function () {
        $scope.showMarque = true;
        $scope.showGamme = true;
        $scope.showFamille = true;
        $scope.showSousfamille = false;
        $scope.showProduit = true;
    }
    $scope.produitOnly = function () {
        $scope.showMarque = true;
        $scope.showGamme = true;
        $scope.showFamille = true;
        $scope.showSousfamille = true;
        $scope.showProduit = false;
    }

    $scope.moveCSV = function (element) {
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.csvfile.filetxt = event.target.result;
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };

    vm.importWavesoftProduits = function () {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('produits/importWavesoftProduits', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succé'),
                        $translate.instant(response.data.message),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Warning'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };

    vm.importWavesoftPrix = function () {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('produits/importWavesoftPrix', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succé'),
                        $translate.instant(response.data.message),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };


    vm.nomadisImportProducts = function () {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('produits/nomadisImportProducts', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succée'),
                        $translate.instant(response.data.message),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };


    vm.downloadModelProduits = function () {
        var filename = 'Produits';
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [csvModel, []]);
    };

    var csvModel = {
        sheetid: 'Produits',
        headers: true,
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'ordre', title: 'ordre' },
            { columnid: 'code', title: 'code' },
            { columnid: 'code_a_barre', title: 'code_a_barre' },
            { columnid: 'libelle', title: 'libelle' },
            { columnid: 'sousfamille_code', title: 'sousfamille_code' },
            { columnid: 'marque_code', title: 'marque_code' },
            { columnid: 'gamme_code', title: 'gamme_code' },
            { columnid: 'famille_code', title: 'famille_code' },
            { columnid: 'fournisseur_code', title: 'fournisseur_code' },
            { columnid: 'type', title: 'type' },
            { columnid: 'unite', title: 'unite' },
            { columnid: 'tva', title: 'tva' },
            { columnid: 'qte_stock', title: 'qte_stock' },
            { columnid: 'qte_vendu', title: 'qte_vendu' },
            { columnid: 'code_garantie', title: 'code_garantie' },
            { columnid: 'date_debut_pack', title: 'date_debut_pack' },
            { columnid: 'date_fin_pack', title: 'date_fin_pack' },
            { columnid: 'qte_min', title: 'qte_min' },
            { columnid: 'activite_code', title: 'activite_code' },
            { columnid: 'image', title: 'image' },
            { columnid: 'hateur', title: 'hateur' },
            { columnid: 'largeur', title: 'largeur' },
            { columnid: 'profondeur', title: 'profondeur' },
            { columnid: 'prix_ht', title: 'prix_ht' },
            { columnid: 'prix_ht1', title: 'prix_ht1' },
            { columnid: 'prix_ht2', title: 'prix_ht2' },
            { columnid: 'prix_ttc', title: 'prix_ttc' },
            { columnid: 'prix_ttc1', title: 'prix_ttc1' },
            { columnid: 'prix_ttc2', title: 'prix_ttc2' },
            { columnid: 'prix_promotion', title: 'prix_promotion' },
            { columnid: 'code_promotion', title: 'code_promotion' },
            { columnid: 'is_pack', title: 'is_pack' },
            { columnid: 'num_serie', title: 'num_serie' },
            { columnid: 'colisage', title: 'colisage' },
            { columnid: 'actif', title: 'actif' },
            { columnid: 'nbrpoints', title: 'nbrpoints' }

        ],
        rows: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };


    vm.getAllPricesForProduct = function (produit_code) {
        WS.get('produits/getAllPricesForProduct/' + produit_code)
            .then((response) => {
                vm.prices = response.data;
                $scope.$apply();
            });
    };


    vm.updatePrices = function (prices) {
        WS.post('produits/updatePrices', prices)
            .then((response) => {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succée'),
                        $translate.instant('Prix modifié avec succé'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function () {
                        $window.location.reload();
                    });
                }
            },
                (error) => {

                })
    };

    vm.generateCAB = (code_a_barre) => {
        WS.getData('produits/generateCAB', { code_a_barre })
            .then((response) => {
                console.log(response);
                $('.block_cab').empty();
                $('.block_cab').append(response.data.data);
                vm.printElement('print_block_cab');
            },(error)=>{
                swal(
                    $translate.instant('Oups'),
                    $translate.instant('Code à barres dépasse 13 caractères'),
                    'warning'
                )
            });
    };

    vm.printElement = function (block_id) {

        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                `);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById(block_id).innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    vm.selectRoutes = function () {
        WS.get('routing/selectRouteWithDepotUser')
            .then(function (response) {
                vm.routings = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
}
export default {
    name: 'produitsController',
    fn: produitsController
};