function mouvement_clientsController($translate, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;

    vm.per_page = "200";


    vm.getMouvement_clients = (page = 1, per_page = vm.per_page) => {
        var data = {
            'date_debut': vm.date_debut,
            'date_fin': vm.date_fin,
            'commercial': vm.commercial,
            'mvt_libelle': vm.mvt_libelle,
            page : page,
            per_page : per_page
        }
        WS.getData('mouvement_clients/getMouvement_clients', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.mouvement_clients = response.data.data;
                    vm.current_page = response.data.current_page;
                    vm.previous = vm.current_page - 1;
                    vm.next = vm.current_page + 1;
                    vm.from = response.data.form;
                    vm.last_page = response.data.last_page;
                    vm.next_page_url = response.data.next_page_url;
                    vm.per_page = response.data.per_page;
                    vm.prev_page_url = response.data.prev_page_url;
                    vm.to = response.data.to;
                    vm.total = response.data.total;
                    vm.paginate = [];
                    for (var i = 1; i <= vm.last_page; i++) {
                        vm.paginate.push(i);
                    }
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }

    

    vm.selectUsers = function() {
        WS.get('users/selectUsers')
            .then(function(response) {
                vm.selectusers = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.exportExcel = function() {
        var stylecsv = {
            sheetid: $translate.instant('Mouvements Clients ') + moment().format("DD-MM-YYYY HH:mm:ss"),
            headers: true,
            // caption: {
            //     title: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
            //     style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
            // },
            //style:'background:#fff',
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [
                { columnid: 'id', title: $translate.instant('N°') },
                { columnid: 'date', title: $translate.instant('Date') },
                { columnid: 'user_code', title: $translate.instant('Code Commercial') },
                { columnid: 'user', title: $translate.instant('commercial/chauffeur') },

                { columnid: 'commercial_code', title: $translate.instant('Code Pré vendeur') },
                { columnid: 'commercial', title: $translate.instant('Pré vendeur') },

                { columnid: 'depot_code', title: $translate.instant('Dépot') },
                { columnid: 'client_code', title: $translate.instant('Code client') },
                { columnid: 'client', title: $translate.instant('Client') },
                { columnid: 'mouvement_libelle', title: $translate.instant('Operation') },
                { columnid: 'payement_code', title: $translate.instant('Type') },
                { columnid: 'entetcommercial_code', title: $translate.instant('Code') },
                { columnid: 'entetcommercial_numero', title: $translate.instant('Reference') },
                { columnid: 'sens_ecriture', title: $translate.instant('Sens') },
                { columnid: 'montant', title: $translate.instant('Montant') },
                { columnid: 'cree_par', title: $translate.instant('Créé par') },
            ],
            row: {
                style: function(sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        var filename = $translate.instant('Mouvements des clients') + ' ' + moment().format("DD-MM-YYYY_HH:mm:ss");
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, vm.mouvement_clients]);
    
    };

    

    $scope.moveCSV = function(element) {
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.csvfile.filetxt = event.target.result;
            $scope.$apply(function() {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };

    vm.importWavesoftMouvement = function() {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('bl/importWavesoftMouvement', vm.csvfile, "POST")
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            }, function(error) {
                console.log(error);
            });
    };

    vm.importExcelMouvement = function() {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('mouvements/importExcelMouvement', vm.csvfile, "POST")
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            }, function(error) {
                console.log(error);
            });
    };

}
export default {
    name: 'mouvement_clientsController',
    fn: mouvement_clientsController
};